<template>
  <div class="container container-news">
    <div class="col-md-12">
      <a :href="`/berita`" class="text-grey-dark mt-4 d-block">
        <i class="feather icon-arrow-left font-20"></i>
      </a>
    </div>
    <div class="_sub_news mt-4 mt-res-1">
      <div class="row">
        <div class="col-md-7">
          <div class="content mb-40">
            <div class="_content-news has-detail">
              <img v-if="detail.banner" :src="detail.banner" alt="">
              <div class="_sub">
                <span class="d-block font-14px font-weight-6 mb-5">{{ detail.title }}</span>
                <span class="d-block font-12 color-grey mb-10"> 
                  {{ detail.timestamp_publish_at | date_only }}  
                  <span class="mr-3 ml-3 ml-res-1 mr-res-1"> &#9679; </span>  
                  {{ detail.timestamp_publish_at | time_only }} 
                  <span class="mr-3 ml-3 ml-res-1 mr-res-1"> &#9679; </span>   
                  Penulis : {{ detail.user }}	 
                  <span class="mr-3 ml-3 ml-res-1 mr-res-1"> &#9679; </span>    
                  Dilihat {{ detail.viewers }} kali 
                </span>
                <p class="mb-19 ln-2  bc-grey  border-bot-1 pb-4 font-14 color-grey-dark" v-html="detail.content"></p>
                <div class="for-sosmed-news">
                  <span class="share grey font-14 mr-1">Share</span>

                  <!-- Go to www.addthis.com/dashboard to customize your tools -->
                  <div class="addthis_inline_share_toolbox"></div>
                </div>
              </div>
            </div>    
          </div>      
        </div>
        <div class="col-md-5">
          <div class="news_sugest mb-20">
            <h3 class="title font-weight-6 font-18 font-res-m-16 mb-19">Informasi Terbaru</h3>
            <template v-for="(berita, index) in beritaTerbaru">
              <div class="for-loop _news" :key="index">
                <router-link :to="`/berita/${berita.slug}`">
                  <template v-if="berita.banner">
                    <img :src="berita.banner" alt="Berita">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/compro/misc/default-image.jpg" alt="">
                  </template>
                  <h2 class="font-weight-6 font-14 elipsis-2-char">{{ berita.title | sort_desc(80) }}</h2>
                  <p class="font-14 color-grey-dark elipsis-2-char mt-res-5" style="padding-left: 120px">
                    {{ berita.content | sort_desc(80) }}
                  </p>
                  <span class="for-date">{{ berita.timestamp_publish_at | date_only }} | {{ berita.timestamp_publish_at | time_only }}</span>
                </router-link>
              </div>
            </template>
          </div>
          <div class="news_sugest mb-20">
            <h3 class="title font-weight-6 font-18 font-res-m-16 mb-19">Berita Terpopuler</h3>
            <template v-for="(berita, index) in beritaPopuler">
              <div class="for-loop _news" :key="index">
                <router-link :to="`/berita/${berita.slug}`">
                  <template v-if="berita.banner">
                    <img :src="berita.banner" alt="Berita">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/compro/misc/default-image.jpg" alt="">
                  </template>
                  <h2 class="font-weight-6 font-14 elipsis-2-char">{{ berita.title | sort_desc(80) }}</h2>
                  <p class="font-14 color-grey-dark elipsis-2-char mt-res-5" style="padding-left: 120px">
                    {{ berita.content | sort_desc(80) }}
                  </p>
                  <span class="for-date">{{ berita.timestamp_publish_at | date_only }} | {{ berita.timestamp_publish_at | time_only }}</span>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: {},
      beritaPopuler: [],
      beritaTerbaru: []
    };
  },
  watch: { 
    $route(to, from){ 
      // react to route changes... 
      if(to !== from){ location.reload(); } 
    } 
  },
  mounted() {
    this.getDetail()
    this.getBeritaTerbaru()
    this.getBeritaPopuler()
  },
  methods: {
    getDetail() {
      this.axios.get(`news/slug/${this.$route.params.slug}`)
        .then( response => {
          this.detail = response.data.result.news
          let isViewed = this.saveLastView('berita', this.detail.id)
          if (isViewed) {
            this.setViewed(this.detail.id)
          }
        }).catch( () => {
          this.$router.push('/404')
        })
    },
    setViewed(id) {
      this.axios.put(`news/${id}/view`)
      .then( () => {
        //console.log(response)
      })
    },
    getBeritaPopuler() {
      this.axios.get(`news`, {
        params: {
          page: 1,
          limit: 5,
          sortBy: 'viewers',
          sortType: 'DESC',
          status: 1
        }
      }).then( response => {
        this.beritaPopuler = response.data.result.news
      })
    },
    getBeritaTerbaru() {
      this.axios.get(`news`, {
        params: {
          page: 1,
          limit: 5,
          sortBy: 'publish_at',
          sortType: 'DESC',
          status: 1
        }
      }).then( response => {
        this.beritaTerbaru = response.data.result.news
      })
    }
  }
};
</script>